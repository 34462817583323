// import MatchHeight from "matchheight/dist/MatchHeight";
// const {initAnimaCounter} = require('anima-counters');
$ = jQuery;

// Call Functions
$(document).ready(function () {
    var loadingTextInterval = null;
    animaCountersSpeed();
    initAnimaCounter(); // this function is
    initStageSlider();
    initTeaserSlider();
    initVideoSlider();
    imageCopyright();
    initCalendarDateSlider();
    initCalendarContentSlider();
    initHeaderSticky();
    toggleHambugerMenu();
    toggleLoginLayer();
    checkScreenSize();
    toggleSocialMediaLayer();
    initButtonSticky();
    stickyGlossary();
    listenToGlossaryAccordion();
    showYoutubeVideo();
    initCookieBanner();
    copyPageUrlToClipboard();
    initTestingCount();
    initCaptcha();
    initCertificateLoadingSpinner();
    fillTestingUrl();
    addToSession();
    disableEnterKey("#profile_form_password");
    $(window).on("resize", function (e) {
        checkScreenSize();
        showMainMenu();
    });
})

function checkScreenSize() {
    var newWindowWidth = $(window).width();
    if (newWindowWidth < 992) {
        toggleLoginLayerAndMenu();
    }
}

if (document.addEventListener) {
    window.addEventListener('pageshow', function (event) {
        initProjectFilter();
        resetFilterEls();
        if (event.persisted || performance.getEntriesByType("navigation")[0].type === 'back_forward') {
            checkSession();

            function first(callback) {
                applyFilter();
                callback();
            }

            function second() {
                sessionStorage.clear();
            }

            first(second);
        }
        resetFilter();
    }, false);
}

function initMatchHeight() {
    MatchHeight.init()
}

function imageCopyright() {
    $('.img-copyright__btn--open').bind('click', function () {
        $(this).toggleClass('show');
        //$(".layer-copyright").slideToggle(200);
    });
    $('.img-copyright__btn--close').bind('click', function () {
        $(this).closest('.img-copyright__layer').siblings('.img-copyright__btn--open').toggleClass('show');
        //$(".layer-copyright").slideToggle(200);
    });
}

function initStageSlider() {
    const stageSlider = $(".slider-stage"),
        stageSliderCounter = stageSlider.siblings('.slide__controls').find('.slide__navigation').find('.slide__pages');

    if (stageSlider.length) {
        stageSlider.on('init reInit afterChange', function (event, slick, currentSlide) {
            //currentSlide is undefined on init -- set it to 0 in this case (currentSlide is 0 based)
            const i = (currentSlide ? currentSlide : 0) + 1;
            stageSliderCounter.text(i + '/' + slick.slideCount);
        })

        stageSlider.slick({
            infinite: true,
            fade: true,
            speed: 1000,
            slidesToShow: 1,
            prevArrow: $('#slider-stage-prev'),
            nextArrow: $('#slider-stage-next'),
            //autoplay: true,
            //autoplaySpeed: 5000,

            responsive: [{
                breakpoint: 768, settings: {
                    fade: false,
                }
            }]
        });
    }
}

function initTeaserSlider() {
    const teaserSlider = $(".slider-box"),
        teaserSliderCounter = teaserSlider.siblings('.slide__controls').find('.slide__navigation').find('.slide__pages');

    if (teaserSlider.length) {
        teaserSlider.on('init reInit afterChange', function (event, slick, currentSlide) {
            //currentSlide is undefined on init -- set it to 0 in this case (currentSlide is 0 based)
            const i = (currentSlide ? currentSlide : 0) + 1;
            teaserSliderCounter.text(i + '/' + slick.slideCount);
            initMatchHeight();
        });

        teaserSlider.slick({
            infinite: true,
            slidesToShow: 3,
            prevArrow: $('#slider-box-prev'),
            nextArrow: $('#slider-box-next'),

            responsive: [{
                breakpoint: 1024, settings: {
                    slidesToShow: 2, infinite: true
                }
            }, {
                breakpoint: 768, settings: {
                    slidesToShow: 1,
                }
            }, {
                breakpoint: 300, settings: "unslick" // destroys slick
            }]
        });
    }
}

function initVideoSlider() {
    const videoSlider = $(".slide-video__items"),
        videoSliderCounter = videoSlider.parent().siblings('.slide__controls').find('.slide__navigation').find('.slide__pages');

    if (videoSlider.length) {
        videoSlider.on('init reInit afterChange', function (event, slick, currentSlide) {
            //currentSlide is undefined on init -- set it to 0 in this case (currentSlide is 0 based)
            const i = (currentSlide ? currentSlide : 0) + 1;
            videoSliderCounter.text(i + '/' + slick.slideCount);
        });

        videoSlider.slick({
            infinite: true,
            slidesToShow: 3,
            adaptiveHeight: true,
            variableWidth: true,
            swipeToSlide: true,
            touchThreshold: 200,
            prevArrow: $('#slider-video-prev'),
            nextArrow: $('#slider-video-next'),

            responsive: [{
                breakpoint: 600, settings: {
                    slidesToShow: 2,
                }
            }, {
                breakpoint: 300, settings: "unslick" // destroys slick
            }]
        });
    }
}

function initCalendarDateSlider() {
    const calendarSlider = $(".calendar__tabs"), calendarInner = $(".calendar--inner");

    if (calendarSlider.length) {
        const navItem = calendarSlider.find('.nav-item');

        if ($(window).width() > 568) {
            navItem.each(function () {
                $(this).removeAttr('aria-hidden')
            });
        } else {
            calendarSlider.on('afterChange', function () {
                const currentSlide = calendarSlider.find('.slick-current'),
                    currentSlideLink = currentSlide.find('.nav-link'),
                    otherSlides = currentSlide.siblings('.slick-slide'),
                    otherSlideLinks = otherSlides.find('.nav-link');
                otherSlideLinks.removeClass('active');
                currentSlideLink.addClass('active');
                calendarInner.scrollTop(0);
            });

            calendarSlider.slick({
                prevArrow: $('#icon-pfeil-links'), nextArrow: $('#icon-pfeil-rechts'), responsive: [{
                    breakpoint: 5000, settings: 'unslick'
                }, {
                    breakpoint: 568, settings: {
                        arrows: false,
                        mobileFirst: true,
                        infinite: true,
                        slidesToShow: 1,
                        swipeToSlide: true,
                        touchThreshold: 200,
                        asNavFor: '.calendar__items'
                    }
                }]
            });
        }
    }
}

function initCalendarContentSlider() {
    const calendarContentSlider = $(".calendar__items");

    if (calendarContentSlider.length) {
        const tabPane = calendarContentSlider.find('.tab-pane');

        if ($(window).width() > 568) {
            tabPane.each(function () {
                $(this).removeAttr('aria-hidden')
            });
        }
        else {
            let selectedIndex = 0
            calendarContentSlider.on('init', function() {
                selectedIndex = $('.nav-link[aria-selected=true]').parents('.nav-item').data('slick-index');
            });
            calendarContentSlider.on('afterChange', function () {
                const currentSlide = calendarContentSlider.find('.slick-current'),
                    tabPane = calendarContentSlider.find('.tab-pane'),
                    otherSlides = currentSlide.siblings('.slick-slide');
                otherSlides.removeClass('show');
                currentSlide.addClass('show')
            });

            calendarContentSlider.slick({
                responsive: [{
                    breakpoint: 5000, settings: 'unslick'
                }, {
                    breakpoint: 568, settings: {
                        arrows: false,
                        adaptiveHeight: true,
                        mobileFirst: true,
                        infinite: true,
                        slidesToShow: 1,
                        swipeToSlide: true,
                        touchThreshold: 200,
                        asNavFor: '.calendar__tabs'
                    }
                }]
            });

            calendarContentSlider.slick('slickGoTo', selectedIndex);
        }
    }
}

function toggleHambugerMenu() {
    const loginLayer = $('.layer-login');

    $('.hamburger-menu').bind('click', function () {
        if (loginLayer.hasClass('show')) {
            loginLayer.collapse('hide');
        }
        $(this).toggleClass('show');
        $('.main-menu').slideToggle(600);
    });
}

function toggleLoginLayer() {
    const loginLayer = $('.layer-login'),
        loginCloseBtn = loginLayer.find('.btn-close');

    // clicking close button, closing login layer
    loginCloseBtn.click(function () {
        loginLayer.collapse('hide');
    });
}

function toggleLoginLayerAndMenu() {
    const loginLayer = $('.layer-login'),
        mainMenu = $('.main-menu'),
        hamburgerMenuTrigger = $('.hamburger-menu');

    var newWindowWidth = $(window).width();
    if (newWindowWidth < 992) {
        // showing login layer, closing main menu
        loginLayer.on('show.bs.collapse', function () {
            mainMenu.slideUp('fast');
            hamburgerMenuTrigger.removeClass('show');
        })

        loginLayer.on('hide.bs.collapse', function () {
            mainMenu.css('display', '');
        })
    } else {
        mainMenu.css('display', 'block');
    }
}

function showMainMenu() {
    const mainMenu = $('.main-menu'),
        hamburgerMenuTrigger = $('.hamburger-menu');

    var newWindowWidth = $(window).width();
    if (newWindowWidth >= 992) {
        mainMenu.css('display', 'block');
    } else {
        if(hamburgerMenuTrigger.hasClass("show")) {
            mainMenu.css('display', 'block');
        } else {

            mainMenu.css('display', 'none');
        }
    }
}

function toggleSocialMediaLayer() {
    const socialMedia = $('.layer-social-media'), socialMediaCloseBtn = socialMedia.find('.btn-close');

    socialMediaCloseBtn.click(function () {
        socialMedia.collapse('hide')
    });
}

function initHeaderSticky() {
    const sticky = $('.sticky');

    if (sticky.length > 0) {
        const stickyOffset = sticky.offset().top;

        $(window).scroll(function () {
            const scroll = $(window).scrollTop();

            if (scroll >= stickyOffset) sticky.addClass('fixed'); else sticky.removeClass('fixed');
        });
    }
}

function initButtonSticky() {
    const sticky = $('.btn-sticky');

    if (sticky.length > 0) {
        const stickyOffset = sticky.offset().top;

        $(window).scroll(function () {
            const scroll = $(window).scrollTop();

            if (scroll >= stickyOffset) sticky.addClass('fixed'); else sticky.removeClass('fixed');
        });
    }
}

$.fn.isInViewport = function () {
    const elementTop = $(this).offset().top, elementBottom = elementTop + $(this).outerHeight(),
        viewportTop = $(window).scrollTop(), viewportBottom = viewportTop + $(window).height();

    return elementBottom > viewportTop && elementTop < viewportBottom;
}

function stickyGlossary() {
    const stickyGlossary = $('.glossary__initial');

    if (parseInt($(window).width()) >= 768) {
        if (stickyGlossary.length > 0) {
            $(stickyGlossary).each(function () {
                const currentEl = $(this), parentRow = currentEl.closest('.glossary__initial__wrapper'),
                    stickyOffset = $(this).offset().top;

                $(window).scroll(function () {
                    const scroll = $(window).scrollTop();
                    if (parentRow.isInViewport()) {
                        if (scroll >= stickyOffset) {
                            stickyGlossary.removeClass('fixed')
                            currentEl.addClass('fixed')
                        } else {
                            currentEl.removeClass('fixed')
                        }
                    } else {
                        currentEl.removeClass('fixed')
                    }
                })
            });
        }
    }
}

function listenToGlossaryAccordion() {
    const accordionCollapse = $('.accordion-collapse');

    $('.accordion-button').on('click', function () {
        stickyGlossary();
    });

    $(window).on('resize', function () {
        stickyGlossary();
    })

    accordionCollapse.on('show.bs.collapse', function () {
        $(this).closest('.accordion-item').animate({marginBottom: '0'}, 500);
    });

    accordionCollapse.on('hide.bs.collapse', function () {
        $(this).closest('.accordion-item').animate({marginBottom: '25px'}, 500);
    });
}

function showYoutubeVideo() {
    $('.video__trigger').on('click', function () {
        const videoParent = $(this).closest('.video--responsive'), videoSource = videoParent.find('iframe').data('src'),
            videoAdvice = videoParent.find('.video--advice');

        videoParent.css('background-image', 'none');
        videoParent.find('iframe').attr('src', videoSource).removeAttr('hidden');
        videoAdvice.fadeOut('fast');
    });
}

function initCookieBanner() {
    const cookieBannerWrapper = $('.cookie-banner'), cookieName = 'invitecb', cookieVal = getCookie(cookieName);

    if (!cookieVal) {
        $(cookieBannerWrapper).fadeIn("fast");
    }

    $('.cookie-banner__button').on('click', function (e) {
        e.preventDefault();
        setCookie(cookieName, 1, 1);
        closeCookieBar();
    });

    function getCookie(name) {
        const value = "; " + document.cookie, parts = value.split("; " + name + "=");

        if (parts.length === 2) {
            return parts.pop().split(";").shift();
        }
    }

    function setCookie(name, value, days) {
        let expires;

        if (days) {
            var date = new Date();
            date.setMonth(date.getMonth() + 1);
            expires = "; expires=" + date.toGMTString();
        } else {
            expires = "";
        }
        document.cookie = name + "=" + value + expires + "; path=/";
    }

    function closeCookieBar() {
        cookieBannerWrapper.fadeOut("fast");
    }
}

function copyToClipboard(text, target) {
    const copiedMessage = $(target).find('.link--copy-url--copied');
    setTimeout(function () {
        copiedMessage.hide();
    }, 800);

    copiedMessage.show();

    if (!navigator.clipboard) {
        // use old deprecated commandExec() way
        const $temp = $("<input>");
        $("body").append($temp);
        $temp.val(text);
        $temp.select();
        document.execCommand("copy");
        $temp.remove();
    } else {
        navigator.clipboard.writeText(text);
    }
}

function copyPageUrlToClipboard() {
    $('.link--copy-url--copied').hide();

    $('.link--copy-url').on('click', function (e) {
        e.preventDefault();
        const text = window.location.href;
        copyToClipboard(text, $(this));
    });
}

function filterCategory(cat1, cat2, cat3) {
    // hide all projects initially
    $('.teaser--filtered').children('[class*="col-"]').hide();

    let selector = ".teaser--filtered .teaser";

    // target group
    if (cat1 !== 'All') {
        selector = '[data-targetgroup*=' + cat1 + "]";
    }

    // skill
    if (cat2 !== 'All') {
        selector = selector + '[data-skill*=' + cat2 + "]";
    }

    // only show testable projects
    if (cat3 === true) {
        selector = selector + '[data-testable*=' + cat3 + "]";
    }

    if (cat1 === 'All' && cat2 === 'All' && cat3 === false) { // when no filter is set
        $('.filter__reset').hide();
    } else {
        $('.filter__reset').show();
    }

    // show all results
    $(selector).parent('[class*="col-"]').show();

    // count amount of projects
    $('.project__counter').text($(selector).length)
}

function populateFilters() {
    const teaser = $('.teaser--filtered .teaser');

    let targetGroups = teaser.map((i, teaser) => $(teaser).data("targetgroup") ? $(teaser).data("targetgroup") : null).get(),
        skills = teaser.map((i, teaser) => $(teaser).data("skill") ? $(teaser).data("skill") : null).get();

    $.each(skills, function (index, value) {
        let newValues = value.split(",");
        skills = $.grep(skills, function (val) {
            return val !== value;
        });

        $.each(newValues, function (newIndex, newVal) {
            skills.push($.trim(newVal));
        })
    });

    $.each(targetGroups, function (index, value) {
        let newValues = value.split(",");
        targetGroups = $.grep(targetGroups, function (val) {
            return val !== value;
        });

        $.each(newValues, function (newIndex, newVal) {
            targetGroups.push($.trim(newVal));
        })
    });

    function getUniqueArray(arr) {
        return arr.filter(function (itm, i, a) {
            return i === a.indexOf(itm);
        });
    }

    const uniqueTargetGroups = getUniqueArray(targetGroups),
        uniqueSkills = getUniqueArray(skills);

    uniqueTargetGroups.sort(function (a, b) {
        return a.localeCompare(b);
    })

    uniqueSkills.sort(function (a, b) {
        return a.localeCompare(b);
    })

    const targetGroupHtml = uniqueTargetGroups.map(targetgroup => {
        return `<option value="${targetgroup}">${targetgroup}</option>`;
    }).join('');

    const skillHtml = uniqueSkills.map(skill => {
        return `<option value="${skill}">${skill}</option>`;
    }).join('');

    $('select#targetgroup option:not(:first)').remove();
    $('select#skill option:not(:first)').remove();
    $('select#targetgroup').append(targetGroupHtml);
    $('select#skill').append(skillHtml);
}

function initProjectFilter() {
    populateFilters();

    // hide reset filter initially
    $('.filter__reset').hide();

    // count number of projects on page load
    $('.project__counter').text($('.teaser--filtered .teaser').length)

    // call the filtering function when filters are changed
    $('.project__filter').change(function () {
        applyFilter();
    });

    // on click of detail link
    $('.link--project-detail').click(function () {
        sessionStorage.setItem('testable', $('.project__filter#testable').is(":checked"));
        sessionStorage.setItem('skill', $('.project__filter#skill').val());
        sessionStorage.setItem('targetgroup', $('.project__filter#targetgroup').val());
    })
}

function checkSession() {
    let targetGroup = $('.project__filter#targetgroup'),
        skill = $('.project__filter#skill'),
        testable = $('.project__filter#testable');

    if (sessionStorage.length > 0 && (sessionStorage.getItem("skill") || sessionStorage.getItem("targetgroup") || sessionStorage.getItem("testable"))) {
        skill.children("option").removeAttr("selected");
        skill.children("option[value='" + sessionStorage.getItem("skill") + "']").attr("selected", true);
        targetGroup.children("option").removeAttr("selected");
        targetGroup.children("option[value='" + sessionStorage.getItem("targetgroup") + "']").attr("selected", true);
        if (sessionStorage.getItem("testable") === "true") {
            testable.attr("checked", true);
            testable.prop('checked', true);
        }
    }
}

function applyFilter() {
    filterCategory($('.project__filter#targetgroup').val(), $('.project__filter#skill').val(), $('.project__filter#testable').is(":checked"));
}

function resetFilterEls() {
    const projectFilter = $(".project__filter"),
        projectFilterTestable = $(".project__filter#testable");

    projectFilter.prop('selectedIndex', 0);
    projectFilter.removeAttr('selected');
    projectFilter.val($(".project__filter option:first").val());
    projectFilterTestable.prop('checked', false);
    projectFilterTestable.removeAttr('checked');
}

function resetFilter() {
    // reset filter
    $('.filter__reset').on("click", function () {
        resetFilterEls();
        sessionStorage.clear();
        applyFilter();
        return false;
    })
}

function initTestingCount() {
    $('.testing-button, .testing-button--modal-trigger').click(function () {
        let urlTestingCount = $(this).data('count-url');
        if (urlTestingCount) {
            fetch(urlTestingCount, {
                headers: {
                    "X-Requested-With": "XMLHttpRequest"
                }
            })
                .then((response) => response.json())
                .then((data) => console.log(data));
        }
    });
}

function initCaptcha() {
    let captchaUrl = $('#url-captcha').val(),
        captchaRefreshBtn = $('#refresh-captcha'),
        captchaLabel = $('#captcha-label');

    if (captchaUrl && captchaRefreshBtn) {
        captchaRefreshBtn.click(function (e) {
            if (!e.target.matches('#refresh-captcha')) return;

            e.preventDefault();

            fetch(captchaUrl)
                .then((response) => response.text())
                .then((data) => captchaLabel.html(data));
        });
    }
}

function downloadCertificate(urlCertificate) {
    return new Promise(function (resolve, reject) {
        $.ajax({
            url: urlCertificate,
            success: function (data) {
                // Resolve promise and go to then()
                resolve(data);
            },
            error: function (err) {
                reject(err) // Reject the promise and go to catch()
            },
        });
    });
}

function changeIndicatorText() {
    let animationTextLength = $('.indicator-text').find('[id^=indicator-text]').length

    let i = 0;

    loadingTextInterval = setInterval(function () {
        if (i === animationTextLength) {
            i = 0;
        }
        $('#indicator-text-' + i).fadeOut(400, function () {
            i++;
            $('#indicator-text-' + (i % animationTextLength)).fadeIn(400);
        });
    }, 2000)

    return true;
}

function initCertificateLoadingSpinner() {
    let downloadBtn = $('.certificate-download'),
        globalIndicator = $('[id^=certificate-download-indicator]');

    globalIndicator.hide();

    if (downloadBtn) {
        downloadBtn.each(function () {
            let urlCertificate = $(this).data("url-certificate");

            if (urlCertificate) {
                let d = new Date(),
                    strDate = d.getFullYear() + "/" + (d.getMonth() + 1) + "/" + d.getDate(),
                    projectId = urlCertificate.substring(urlCertificate.lastIndexOf('/') + 1),
                    indicator = $('#certificate-download-indicator-' + projectId);

                $(this).click(function (e) {
                        e.preventDefault();
                        indicator.show();

                        changeIndicatorText();

                        downloadCertificate(urlCertificate).then(function (data) {
                            // Run this when your request was successful
                            $('body').append('<a id="certificate-download-link-' + projectId + '" href="' + data + '" class="d-none" download="Zertifikat_' + strDate + '"></a>');
                            let certificateDownloadLink = $('#certificate-download-link-' + projectId);
                            certificateDownloadLink[0].click();
                            certificateDownloadLink.remove();
                            indicator.hide();
                            clearInterval(loadingTextInterval);
                            $('.indicator-text').find('[id^=indicator-text]').hide();
                            $('.indicator-text').find('[id^=indicator-text]:first-child').show();
                        }).catch(function (err) {
                            // Run this when promise was rejected via reject()
                            console.log(err);
                            indicator.hide();
                            clearInterval(loadingTextInterval);
                            $('.indicator-text').find('[id^=indicator-text]').hide();
                            $('.indicator-text').find('[id^=indicator-text]:first-child').show();
                        })
                    }
                )
            }
        })
    }
}

function animaCountersSpeed() {
    $('.anima-counter').each(function () {
        if ($(this).text() <= 50) {
            $(this).attr('data-anima-counter-duration', $(this).text() * 10);
        }
    })
}

function fillTestingUrl() {
    $(".testing-button--modal-trigger").click(function () {
        let targetUrl = $(this).data('target-url');
        let projectHasAuth = $(this).data('project-hasauth');
        $('#testingModal').on('shown.bs.modal', function () {
            $(this).find('.modal-body').hide();
            $(this).find('#closeBtn').hide();
            $(this).find('#targetUrl').hide();
            if (projectHasAuth) {
                $(this).find('#targetUrl').addClass('invisible');
                $(this).find('.modal-body#modal-project-has-auth').show();
                $(this).find('#closeBtn').show();
            } else {
                $(this).find('#targetUrl').removeClass('invisible');
                $(this).find('.modal-body#modal-project-has-no-auth').show();
                $(this).find('#targetUrl').attr("href", targetUrl);
                $(this).find('#targetUrl').show();
            }
        })
    })
}

function addToSession() {
    $('.testing-button--modal-trigger').click(function () {
        let url = $(this).data('prototype-url');
        if (url) {
            $('#testingModal .testing-modal__submit').on("click", function () {
                fetch(url, {
                    headers: {
                        "X-Requested-With": "XMLHttpRequest"
                    }
                })
                    .then((response) => response.json())
                    .then((data) => console.log(data));
            })
        }
    });
}

function disableEnterKey(el) {
    const deleteAccWarning = $('#delete-account-warning');
    deleteAccWarning.hide();
    // If it is Enter, then it will return false and anything will stop immediately, so the form won't be submitted.
    $(document).on("keydown", el, function (event) {
        if (event.key === "Enter" || event.keyCode === 13) {
            deleteAccWarning.show();
            event.preventDefault();
            return false;
        }
    });

    deleteAccWarning.on("close.bs.alert", function () {
        deleteAccWarning.hide();
        $("#delete-button").focus();
        return false;
    })
}